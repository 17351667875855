var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('div',[((_vm.needToAddBankAccount || _vm.needToUpdateKyc || _vm.needToAddFiles || _vm.verificationPending) && _vm.bankDataReady)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('Your stripe account is not verified.'))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Verify your account by completing these steps:'))+" ")]):_vm._e(),(!_vm.needToUpdateKyc && !_vm.needToAddBankAccount && !_vm.needToAddFiles && !_vm.verificationPending)?_c('div',{staticClass:"mb-4 text-success"},[_vm._v(" "+_vm._s(_vm.$t( 'Your KYC verification has been successfully completed. You can now upgrade your membership and begin working on your first projects. Best of luck!' ))+" ")]):_vm._e(),(!_vm.isDisabled)?[(_vm.bankDataReady)?_c('BankDetails',{attrs:{"error":_vm.error,"loading":_vm.loading,"needToAddBankAccount":_vm.needToAddBankAccount,"needToUpdateKyc":_vm.needToUpdateKyc,"needToAddFiles":_vm.needToAddFiles,"verificationPending":_vm.verificationPending},on:{"createdBankAccount":_vm.handleCreatedBankAccount}}):_c('div',[_c('PaymentsSkeleton')],1)]:_vm._e(),(
      ((_vm.needToUpdateKyc || _vm.needToAddBankAccount || _vm.needToAddFiles || _vm.verificationPending) && _vm.bankDataReady) ||
      _vm.isDisabled
    )?_c('StripeVerification',{attrs:{"error":_vm.error,"needToUpdateKyc":_vm.needToUpdateKyc,"needToAddFiles":_vm.needToAddFiles,"needToAddBankAccount":_vm.needToAddBankAccount,"stripeConnectLink":_vm.stripeConnectLink,"verificationPending":_vm.verificationPending,"isDisabled":_vm.isDisabled}}):_vm._e(),(_vm.paymentMethodLoading)?[_c('PaymentsSkeleton')]:_vm._e(),(!_vm.paymentMethodLoading)?[(
        (_vm.isVerified || (!_vm.needToAddBankAccount && !_vm.needToUpdateKyc && !_vm.needToAddFiles && !_vm.verificationPending)) &&
        !_vm.isDisabled
      )?_c('BillingDetails',{attrs:{"paymentMethods":_vm.paymentMethods,"error":_vm.error,"loading":_vm.loading,"showCCForm":_vm.showCCForm,"cardAdded":_vm.cardAdded},on:{"createPaymentMethod":_vm.createPaymentMethod,"toggleForm":function($event){_vm.showCCForm = !_vm.showCCForm}}}):_vm._e()]:_vm._e(),(!_vm.needToAddBankAccount && !_vm.needToUpdateKyc && !_vm.needToAddFiles && !_vm.verificationPending && !_vm.isDisabled)?[_c('Balance',{attrs:{"balance":_vm.balance,"currency":_vm.currency},on:{"getPaid":_vm.getPaid}}),_c('PayoutDetails',{attrs:{"payouts":_vm.payouts}})]:_vm._e(),(
      _vm.user.role === 'designer' && !_vm.needToAddBankAccount && !_vm.needToUpdateKyc && !_vm.needToAddFiles && !_vm.verificationPending
    )?[_c('div',{staticClass:"col-6 tax__title"},[_vm._v(" "+_vm._s(_vm.$t('Tax information'))+" ")]),_c('div',{staticClass:"payments__block mt-2"},[_c('TaxDetailsDesigner')],1)]:_vm._e()],2):_c('div',[_c('PaymentsSkeleton'),_c('PaymentsSkeleton')],1)}
var staticRenderFns = []

export { render, staticRenderFns }